import React from "react";
import List from "./List";

const Projects = () => {
  return (
    <>
      <List></List>
    </>
  );
};

export default Projects;
