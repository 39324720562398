import React from "react";
import About from "./About";
import Contact from "./Contact";

const ContactUs = () => {
  return (
    <>
      <About></About>
      <Contact></Contact>
    </>
  );
};

export default ContactUs;
