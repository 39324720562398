import React from "react";
import PoolItems from "./PoolItems";

const SwimmingPool = () => {
  return (
    <>
      <PoolItems></PoolItems>
    </>
  );
};

export default SwimmingPool;
